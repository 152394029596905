import lozad from 'lozad'

window.addEventListener('DOMContentLoaded', () => {
  // Lozad set a [data-loaded] attribute when loading image, but does not detect
  // when image is fully loaded, which can cause incoherent animations
  const lazys = document.querySelectorAll('[data-lazyload]')
  for (const image of Array.from(lazys)) {
    image.onload = () => image.setAttribute('data-fully-loaded', true)
  }
  lozad(lazys).observe()
})
